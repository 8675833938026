/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/signia/'], ['en', 'https://www.hear.com/hearing-aids/brands/signia/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/signia/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/signia/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia is one of the largest and most ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "innovative hearing aid"), " brands in the world. They continuously pave the way in the development of technology, focusing on enhancing human performance and removing limitations for those with hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "brilliant-hearing-with-signia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#brilliant-hearing-with-signia",
    "aria-label": "brilliant hearing with signia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Brilliant Hearing with Signia"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-hearing-aids.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia offers a wide range of ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "BTE (Behind-the-ear)"), " and ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "ITE (In-the-ear) hearing aid"), " styles for those with mild to severe hearing loss. So no matter your lifestyle and hearing needs, you’ll experience the same level of brilliant, natural sound in any situation."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to their cutting-edge Integrated Xperience technology platform with RealTime conversation enhancement algorithms, all of the Signia hearing aids successfully deliver on the No. 1 need of those with hearing loss: Speech clarity in group conversations. One of the most exciting features is their ability to dampen disruptive ambient noise so that you can obtain maximum comprehension while interacting with multiple people at the same time.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And through outstanding microphone technology, Signia hearing aids also convey an impressive, three-dimensional “tapestry” of sounds, which means you’ll never miss a moment. Signia hearing aids are designed to help people gain an edge and hear better than they ever have before."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-pure-charge--go-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure-charge--go-ix",
    "aria-label": "signia pure charge  go ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Pure Charge & Go IX"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure-x.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure-charge-go-ix/",
    className: "c-md-a"
  }, "Signia Pure Charge & Go IX hearing aid"), ", featuring their groundbreaking IX-Technology, designed to enhance speech comprehension in various conversation scenarios. This technology aims to improve social interaction and effectively manage group conversations, even in challenging listening environments. The hearing aid offers dynamic real-time conversation enhancement, noise reduction, speech enhancement, and more, ensuring that wearers can actively engage in conversations, regardless of group size or background noise. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears. The Signia Pure Charge & Go IX almost invisible design, with bluetooth and rechargeable features, improve your hearing and interpersonal relationships.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-silk-charge--go-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-charge--go-ix",
    "aria-label": "signia silk charge  go ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Silk Charge & Go IX"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-silk-x.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is as small as a coffee bean and unnoticeable to those around you, the ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/silk-charge-go-ix/",
    className: "c-md-a"
  }, "Signia Silk Charge & Go IX hearing aid"), " is the perfect fit. Signia introduces the groundbreaking Signia Silk Charge & Go IX, an almost invisible in-ear hearing aid with IX-Technology, designed to enhance speech understanding and discretion. This innovative technology is particularly useful in dynamic and noisy conversation situations, making it easy to engage in conversations in various environments. The Silk Charge & Go IX offers a remarkable battery life of up to 24 hours and can be quickly recharged, even on the go. The Signia Silk Charge & Go IX hearing aid renews the joy of conversations in challenging settings, whether at lively festivals or family gatherings, and offers a free trial to experience these benefits firsthand.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-styletto-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto-x",
    "aria-label": "signia styletto x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Styletto X"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-x.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "This slim, behind-the-ear ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth hearing aid"), " has transformed the future of modern hearing aid design. Designed for the active lifestyle, the rechargeable Styletto X hearing aid combines elegance, convenience and functionality to deliver a high-quality hearing experience without compromising style. And no one will know you’re wearing it."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unlike any hearing aid you’ve seen before, this award-winning device features wireless recharging technology and looks more like a sleek Bluetooth earpiece than a hearing aid. Finally, a hearing aid that looks as good as it performs.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signia-motion-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-motion-x",
    "aria-label": "signia motion x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Motion X"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-motion-x.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "All of the Signia hearing aids in the Motion X product line have one thing in common: they pack a huge amount of advanced technology into small, compact shells. The Motion Charge&Go SP X is the world’s first rechargeable superpower BTE and one of three Motion Charge&Go X models, providing up to an astounding 61 hours of wear-time on a single charge."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "SpeechMaster is at the heart of each hearing aid and offers a revolutionary filtering system. It keeps irrelevant noise to a minimum while highlighting speech, resulting in a less stressful, easier listening experience. Motion X gives you an elevated hearing experience and the independence that comes with a hearing aid that lasts all day.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try Signia hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "modern-connectivity-through-easytek",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modern-connectivity-through-easytek",
    "aria-label": "modern connectivity through easytek permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modern Connectivity Through Easytek"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-easytek.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia connectivity device, easyTek, provides simple operation of their latest hearing aids wirelessly connecting them to your TV, smartphones, and many other Bluetooth-capable devices. Once connected, the sound is transmitted via Bluetooth®. Volume, programs, and audio sources can be controlled with an iOS and Android app. The numerous options and programmable settings are essential additions to your hearing aids, creating a seamless, pleasurable experience.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try Signia hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
